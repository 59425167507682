.multicolor-bar {
    overflow: hidden;
    background-color: #f3f4f8;
    border: 1px solid #d9dce1;
    height: 8px;
    margin: 5px 0 10px;
    border-radius: 6px;
}

.multicolor-bar .values .value {
    float: left;
    text-align: center;
    height: 20px;
}

.multicolor-bar .scale .graduation {
    float: left;
    text-align: center;
}

.multicolor-bar .bars {
    height: 100%;
}

.multicolor-bar .bars .bar {
    float: left;
    height: 100%;
}

.multicolor-bar .bars div.bar:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.multicolor-bar .bars div.bar:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.multicolor-bar .legends {
    text-align: center;
}

.multicolor-bar .legends .legend {
    display: inline-block;
    margin: 0 5px;
    text-align: center;
}

.multicolor-bar .legends .legend .dot {
    font-size: 25px;
    vertical-align: middle;
}

.multicolor-bar .legends .legend .label {
    margin-left: 2px;
    vertical-align: middle;
}
