@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: 'Regular', sans-serif;
    font-size: 14px;
}

#root {
    height: 100vh;
}

@font-face {
    font-family: "Regular";
    src: local("Inter-Regular"),
        url("./fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Medium";
    src: local("Inter-Medium"),
        url("./fonts/Inter-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "SemiBold";
    src: local("Inter-SemiBold"),
        url("./fonts/Inter-SemiBold.ttf") format("truetype");
}

input:disabled {
    background-color: #f3f4f8;
}

.react-datepicker__view-calendar-icon input {
    padding: 0.6rem 1rem !important;
}

.login-bg {
    background-image: url('../src/assets/backgrounds/utilisation-login-bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.badge-action {
    background: #C41230;
}

.badge-info {
    background: #795EFF;
}

.badge-alert {
    background:#F5AD22;
}