$transition: opacity 250ms ease-out, transform 300ms ease;
.app-layout__body {
    &-enter,
    &-appear {
        opacity: 0;
        transform: translateX(-3rem);
        &-active {
            opacity: 1;
            transform: translateX(0rem);
            transition: $transition;
        }
    }

    &-exit {
        &-active {
            opacity: 0;
            transform: translateX(3rem);
            transition: $transition;
        }
    }
}
